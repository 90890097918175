<template>
  <div class="container h-100 bg-first p-1">
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-card class="mt-1">
        <div>
          <h3 class="m-0">
            รายการสลากฯ ของฉัน
          </h3>
          <!-- <small>คุณจำหน่ายสลากได้ทั้งหมด
            <span class="text-danger">- ใบ</span> ในการจ่ายเงิน
            เพื่อเป็นเจ้าของรายการสลาก ฯ ที่ท่านสั่งซื้อ</small> -->
        </div>

        <div class="mt-2 d-flex align-items-center justify-content-between">
          <h4>
            ท่านมีสลากสะบายดีงวดล่าสุดทั้งหมด
            <span class="text-danger">{{ count }} เล่ม </span>
            จำนวน <span class="text-danger">{{ count* 100 }} ใบ</span>
          </h4>

          <!-- <h4 class="text-danger">
            รวมทั้งหมด - บาท
          </h4> -->
        </div>

        <div style="overflow-x: auto">
          <table class="table">
            <colgroup>
              <col width="35%">
              <col width="20%">
              <col width="30%">
              <col width="20%">
            </colgroup>
            <thead>
              <tr>
                <th>เล่มสลาก</th>
                <th>สถานะ</th>
                <th>จำนวนที่พร้อมขาย</th>
                <!-- <th>ผลการถูกรางวัล</th> -->
                <th>คืนสลาก</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in lottoset"
                :key="item._id"
              >
                <td>
                  <div class="card-lotto-set">
                    <div class="img-lotto">
                      <img
                        :src="require('@/assets/images/lottery/Logo_about.png')"
                        alt="logo"
                      >
                    </div>

                    <div class="detail-lotto">
                      <div class="d-flex justify-content-between bd">
                        <h5>สลากเล่มที่ {{ item.set_no }}</h5>
                        <h5 class="pink">
                          <small>สลาก</small> 100 <small>ใบ</small>
                        </h5>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>งวดวันที่ {{ item.roundth }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <span
                    v-if="item.status === 2"
                    class="badge badge-light-warning"
                    @click="detail(item.set_no)"
                  >พร้อมขาย</span>
                  <span
                    v-if="item.status === 3"
                    class="badge badge-light-danger"
                  >คืนสลาก</span>
                  <span
                    v-if="item.status === 4"
                    class="badge badge-light-success"
                  >ออกผลรางวัลแล้ว</span>
                </td>
                <td>
                  <!-- <h6 class="mb-0 font-weight-bolder">
                    {{ item.count }} ใบ
                  </h6> -->
                  <span>งวด {{ item.roundth }}</span>
                </td>
                <!-- <td>
                  <h6 class="mb-0">
                    -
                  </h6>
                  <a href="#!"><small>ใบ</small></a>
                </td> -->
                <td>
                  <h6 class="mb-0">
                    <span
                      v-if="item.status === 2"
                      class="btn btn-warning"
                      @click="refund(item.set_no)"
                    >คืนหวย</span>
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-model="currentPage"
            class="mt-1"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalRows"
            first-number
            last-number
            align="center"
            @input="getSet()"
          />
        </div>
        <div class="mt-2">
          <h4 class="text-divider">
            <span class="text-danger text-center">ขายไปแล้ว {{ soldtotal }} ใบ</span>
          </h4>
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              variant="gradient-primary"

              :to="{ name: 'lotto-detail'}"
            >
              ดูเพิ่มเติม
            </b-button>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BButton, BOverlay, BIconController,
  BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Order',
  components: {
    BCard,
    BButton,
    BPagination,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      number: 1,
      selectNum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      cart: [],
      lottoset: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      show: false,
      count: 0,
      soldtotal: 0,
    }
  },
  mounted() {
    this.getSet()
    this.getSetCount()
    this.getLottery()
  },
  methods: {
    refund(id) {
      if (id) {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: 'ยืนยันที่จะคืนสลากหรือไม่ ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            const Obj = {
            // eslint-disable-next-line no-underscore-dangle
              set_no: id,
            }
            this.$http
              .post(
                'https://uatapi.sabaideelotto.com/api/lottery/refund', Obj,
              )
              .then(response => {
                this.getSet()
                if (response.data) {
                  this.show = false
                  this.$swal({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'คืนสลากสำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              })
              .catch(error => console.log(error))
          } else {
            this.show = false
            this.$swal('ยกเลิก', 'ยกเลิกการคืนสลาก !', 'error')
          }
        })
      }
    },
    detail(item) {
      this.$router.push({ name: 'lottery-set-agent', params: { id: item } })
    },
    async getSet() {
      const Obj = {
        page: this.currentPage,
      }
      this.$http
        .post('https://uatapi.sabaideelotto.com/api/lottery/agent/getAgentListbyuser', Obj)
        .then(ress => {
          // console.log(ress.data)
          this.lottoset = ress.data.lottery
          this.totalRows = ress.data.total
          // console.log(this.totalRows)
        })
    },
    getSetCount() {
      this.$http
        .post('https://uatapi.sabaideelotto.com/api/lottery/GetLottoSetLastByagent')
        .then(ress => {
          // console.log(ress.data)
          this.count = ress.data.length
        })
    },
    getLottery() {
      const Obj = {
        page: 1,
      }
      this.$http
        .post('https://uatapi.sabaideelotto.com/api/lottery/getsold', Obj)
        .then(response => {
          // this.items = response.data.lottery
          this.soldtotal = response.data.total
        })
        .catch(error => console.log(error))
    },
    minus() {
      if (this.number > 1) {
        // eslint-disable-next-line no-plusplus
        this.number--
      }
    },
  },

  head() {
    return {
      title: 'รายการสั่งซื้อสลากฯ ทั้งหมดของคุณ',
    }
  },
}
</script>

<style scoped>
.table {
  border-spacing: 0 15px;
  border-collapse: separate;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  scrollbar-width: none;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.table tbody tr {
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.table tbody tr td {
  background: #fff;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
</style>
